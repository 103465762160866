import { useDispatch, useSelector } from "react-redux"
import NavBar from "../NavBar/NavBar"
import "./index.css"
import { useEffect, useState } from "react"
import icon from "../../assets/default-profile-icon.png"
import homeIcon from "../../assets/home-icon.png"
import { getUser } from "../../store/user"
import { getUsersFriends } from "../../store/friend";
import { csrfFetch } from "../../store/csrf"

import { useHistory, useParams } from "react-router-dom"
import MidSection from "../MidSection"
import { linkedInLink, githubLink, airzzzLink, medianLink } from "../../assets/helper";
import linkedInIcon from "../../assets/linkedin-logo.png"
import githubIcon from "../../assets/github-logo.svg"
import airzzzIcon from "../../assets/airzzz-icon.png"
import medianIcon from "../../assets/median-icon.png"
import AllUsersFriends from "../AllUsersFriends"
import SomeUsersFriends from "../SomeUsersFriends"
import FriendshipOption from "../FriendshipOption"
import { getOutgoingRequests } from "../../store/request"
import { getUsersPosts2 } from "../../store/post"


const Profile = () => {
    const { userId } = useParams()
    const currentUser = useSelector(state => state.session.user)
    const user = useSelector(state => state.user.singleUser)
    const dispatch = useDispatch()
    const history = useHistory()
    const [friendsPage, setFriendsPage] = useState(false)
    const [friendsArray, setFriendsArray] = useState([])
    const [status, setStatus] = useState("")
    const requests = useSelector(state => state.request)
    const outgoing = useSelector(state => state.request.outgoing)
    const friendsList = useSelector(state => Object.keys(state.friend.allFriends).length)
    const [profileImage, setProfileImage] = useState("")
    const [bioInput, setBioInput] = useState(false)
    const [bio, setBio] = useState("")

    useEffect(() => {
        dispatch(getUsersPosts2(userId))
    }, [userId])

    useEffect(() => {
        dispatch(getUsersFriends(userId))
            .then((res) => {
                setFriendsArray(res.friends)
                let temp = "not friends"
                if (currentUser.id == userId) {
                    temp = "myself"
                } else {
                    for (let i = 0; i < res.friends.length; i++) {
                        if (res.friends[i].id === currentUser.id) {
                            temp = "friends"
                            break
                        }
                    }
                }
                if (temp === "not friends") {
                    let incomingKeys = Object.keys(requests.incoming)
                    let outgoingKeys = Object.keys(requests.outgoing)
                    if (incomingKeys.includes(userId)) {
                        temp = "incomingRequest"
                    } else if (outgoingKeys.includes(userId)) {
                        temp = "outgoingRequest"
                    }
                }
                setStatus(temp)
            })
    }, [friendsList])

    useEffect(() => {
        setFriendsPage(false)
        if (window.location.pathname.endsWith("/friends")) {
            setFriendsPage(true)
        }
        dispatch(getUser(userId))
            .then(() => {
                dispatch(getUsersFriends(userId))
                    .then((res) => {
                        setFriendsArray(res.friends)
                        let temp = "not friends"
                        if (currentUser.id == userId) {
                            temp = "myself"
                        } else {
                            for (let i = 0; i < res.friends.length; i++) {
                                if (res.friends[i].id === currentUser.id) {
                                    temp = "friends"
                                    break
                                }
                            }
                        }
                        if (temp === "not friends") {
                            let incomingKeys = Object.keys(requests.incoming)
                            let outgoingKeys = Object.keys(requests.outgoing)
                            if (incomingKeys.includes(userId)) {
                                temp = "incomingRequest"
                            } else if (outgoingKeys.includes(userId)) {
                                temp = "outgoingRequest"
                            }
                        }
                        setStatus(temp)
                    })
                window.scrollTo(0, 0)
            })
    }, [outgoing, history.location.pathname])


    const goToProfile = () => {
        history.push(`/users/${currentUser.id}`)
    }

    const goToFriends = (userId) => {
        history.push(`/users/${userId}/friends`)
    }

    const goToProfileUserId = (userId) => {
        history.push(`/users/${userId}`)
    }

    const submit = async (e) => {
        // e.preventDefault();

        console.log("=================2", profileImage)
        const formData = new FormData();
        formData.append("image", profileImage);
        await fetch('/api/posts/checkImage', {
            method: "POST",
            body: formData
        })
            .then(async (res) => {
                let imgurl = await res.text()
                console.log("=================3", imgurl)
                if (imgurl.includes("not permitted")) {
                    alert("Only png/jpg/jpeg/gif allowed")
                    return
                }
                return { image: imgurl };
                // let data = { image: imgurl };
                //dispatch profileUpdate(data)
            })
            .then(async (res) => {
                await csrfFetch("/api/users/profile/image", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(res)
                })
                console.log("=============99", res)
                dispatch(getUser(userId))
            })
    }

    useEffect(() => {
        console.log("=================4")

        if (profileImage) {
            console.log("=================5")
            submit()
        }

    }, [profileImage])

    const changeProfileImage = async (e) => {
        console.log("=================1", e.target.files[0])
        const file = e.target.files[0]
        setProfileImage(file)
    }

    const bioForm = () => {
        setBio(user.bio)
        setBioInput(true)
    }
    const updateBio = async (e) => {
        e.preventDefault()
        let realBio;
        for (let i = 0; i < bio.length; i++) {
            if (bio[i] != " ") {
                realBio = bio.slice(i)
                break
            }
        }
        realBio == undefined ? realBio = "" : realBio = realBio
        let data = { "bio": realBio }
        await csrfFetch("/api/users/profile/bio", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
            .then(() => {
                dispatch(getUser(currentUser.id))
                setBioInput(false)
            })
    }

    return (
        <div>
            <NavBar />
            <div id="profile-container" className="">
                <div id="profile-left" className="">
                    <img className="profile-left-icons cursor" onClick={() => history.push("/")} src={homeIcon} alt="home icon" />
                    <img className="profile-left-icons cursor" onClick={goToProfile} src={currentUser?.profilePicture ? currentUser?.profilePicture : icon} alt="profile icon" />
                    <div id="profile-left-spacer"></div>
                    <img className="profile-left-icons cursor" onClick={linkedInLink} src={linkedInIcon} alt="linkedin icon" />
                    <img className="profile-left-icons cursor" onClick={githubLink} src={githubIcon} alt="github icon" />
                    <img className="profile-left-icons cursor" onClick={medianLink} src={medianIcon} alt="median icon" />
                    <img className="profile-left-icons cursor" onClick={airzzzLink} src={airzzzIcon} alt="airzzz icon" />
                </div>
                <div id="profile-right" className="">
                    <div id="profile-header-container">
                        <div id="profile-header" className="">
                            <img onClick={() => goToProfileUserId(user.id)} className="cursor" id="profile-pic" src={user.profilePicture ? user.profilePicture : icon} alt="profile" />
                            {currentUser && userId == currentUser.id && (
                                <form onSubmit={submit} id="edit-profile-image" className="cursor">
                                    <label id="edit-profile-image-label" for="edit-profile-image-input" className="cursor">📸</label>
                                    <input
                                        id="edit-profile-image-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => changeProfileImage(e)}
                                        hidden
                                    />
                                </form>
                            )}
                            <div onClick={() => goToProfileUserId(user.id)} className="cursor underline-hover" id="profile-name">{user.firstName} {user.lastName}</div>
                            <FriendshipOption status={status} userId={userId} />


                        </div>
                    </div>
                    <div id="profile-body">
                        {friendsPage && (
                            <div>
                                <AllUsersFriends friendsArray={friendsArray} status={status} />
                            </div>
                        )}
                        {!friendsPage && (
                            <>
                                <div id="profile-body-left">

                                    <div id="intro" className="profile-body-left-content">
                                        <div id="intro-title">Intro</div>
                                        <div className="intro-content">

                                            {bioInput && (
                                                <div>

                                                    <form onSubmit={(e) => updateBio(e)}>
                                                        <textarea
                                                            type="text"
                                                            value={bio}
                                                            onChange={((e) => setBio(e.target.value))}
                                                            placeholder="Add bio..."
                                                            // required
                                                            autoComplete="off"
                                                            id="edit-bio-form"
                                                        />
                                                    </form>
                                                    <button onClick={(e) => updateBio(e)} className="intro-content-buttons bold">
                                                        Submit
                                                    </button>
                                                    <button className="intro-content-buttons bold" onClick={() => setBioInput(false)} type="cancel">Cancel</button>
                                                </div>
                                            )}
                                            {!bioInput && (
                                                // <div className="intro-info-title">Bio: </div>
                                                <div className="intro-content-sub">
                                                    <div className="intro-info-title">Bio: </div>
                                                    <div className="intro-info-content">{user.bio}</div>
                                                </div>
                                            )
                                            }
                                            {currentUser && userId == currentUser.id && !user.bio && !bioInput && (
                                                <button className="intro-content-buttons bold" onClick={() => bioForm()}>Add Bio</button>
                                            )}
                                            {currentUser && userId == currentUser.id && user.bio && !bioInput && (
                                                <button className="intro-content-buttons bold" onClick={() => bioForm()}>Edit Bio</button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="profile-body-left-content">
                                        <div id="profile-friend-sample-top">
                                            <div id="profile-friend-sample-top-title">Friends</div>
                                            <div id="see-all-friends" className="cursor" onClick={() => goToFriends(user.id)}>See all friends</div>
                                        </div>
                                        <div id="profile-friend-sample-count">{friendsArray.length} {friendsArray.length > 2 ? "friends" : "friend"}</div>
                                        <SomeUsersFriends friendsArray={friendsArray.slice(0, 6)} />
                                    </div>

                                </div>
                                <div id="profile-body-right">
                                    <MidSection />
                                </div>
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile
